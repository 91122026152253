import { T } from "@/translations";
import { Tooltip } from "antd";
import cx from "classnames";
import upperFirst from "lodash/upperFirst";
import PropTypes from "prop-types";
import React from "react";
// assets
import "./Icon.style.less";

export const ICON_SIZE = {
    STANDARD: "standard",
    BIG: "big",
};

const DEFAULT_TOOLTIPS = {
    trash: "general.tooltip.trash",
    pen: "general.tooltip.edit",
    "ellipsis-h": "general.tooltip.moreActions",
};

/**
 * This component renders a Unicon icon.
 * If it has onClick prop, it will automatically display
 * pointer cursor while hovering over the icon.
 *
 * @deprecated because of Iconfont usage, will be removed in the future
 * @see https://iconscout.com/unicons
 */
export function Icon({
    type,
    tooltip,
    size = ICON_SIZE.STANDARD,
    className,
    disabled,
    onClick,
    ...props
}) {
    const classes = cx(
        `uil uil-${type}`,
        `pmIcon pmIcon--size${upperFirst(size)}`,
        disabled
            ? "pmIcon--disabled"
            : {
                  "pmIcon--withPointer": props.onClick,
              },
        className,
    );

    const tooltipText = getTooltip(type, tooltip);

    const icon = (
        <i className={classes} onClick={!disabled && onClick} {...props} />
    );

    return tooltipText ? <Tooltip tooltip={tooltipText}>{icon}</Tooltip> : icon;
}

Icon.propTypes = {
    /** Unicon icon name */
    type: PropTypes.string,
    size: PropTypes.oneOf(Object.values(ICON_SIZE)),
    tooltip: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

function getTooltip(type, tooltip) {
    if (tooltip) {
        return tooltip;
    }

    const defaultTooltip = DEFAULT_TOOLTIPS[type];

    return defaultTooltip ? <T id={defaultTooltip} /> : null;
}
